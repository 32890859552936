
<div class="ptb-60">
    <div class="container-fluid bg-breadcrumb">
        <div class="container text-center mleft">
            <div class="row">
                <div class="col-lg-9">
                    <div class="search-container d-none d-lg-block">
                        <div class="search-bar">
                            <input type="text" [(ngModel)]="skills" class="search-input input_radius fs-14" placeholder="Skills, Job Title">
                            <input type="text" [(ngModel)]="location" class="search-input fs-14" placeholder="Location">
                            <select [(ngModel)]="selectedExperience" class="experience-dropdown fs-14">
                                <option value="">Exp</option>
                                <option *ngFor="let option of experienceOptions" [value]="option">{{ option }}</option>
                            </select>
                            <button class="find-jobs-btn" (click)="findJobs()">Find Jobs</button>
                        </div>
                    </div>

                    <div class="search-container d-lg-none">
                        <div class="search-bar">
                            <input type="text" class="search-input input_radius fs-12" placeholder="Skills,Title ">
                            <input type="text" class="search-input fs-12" placeholder="Location">
                            <select class="experience-dropdown fs-12">
                                <option value="">Exp</option>
                                <option value="0-1">0-1 Years</option>
                                <option value="2-3">2-3 Years</option>
                                <option value="3-5">3-5 Years</option>
                                <option value="5+">5+ Years</option>
                            </select>
                            <button class="find-jobs-btn"><i class="fas fa-search"></i></button>
                        </div>
                    </div>
                </div>       

                <div class="col-lg-3 d-none d-lg-block">
                    <div class="upload-container  mtop">
                        <a (click)="uploadresume()" class="upload-resume-btn"><i class="fa-solid fa-upload"></i> Upload Your Resume</a>
                    </div>
                </div>
            </div>
     
        </div>
    </div>
    <div class="container-fluid  pb-70" style="    background-color: #f9fafb;">


        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="main-container">
                        <div class="browse-jobs">
                            <div class="section-title">
                                <h2>Career Jobs</h2>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-lg-9">

                    <!-- Browse Jobs Section -->
                    <div class="browse-jobs">

                        <div class="job-categories">
                            <div class="job-box" *ngFor="let item of CategoryData">
                                <div class="job-flex">
                                    <img src="assets/img/images/it.png" alt="IT, Non IT" class="job-image" />
                                    <div class="job-content" (click)="JobsFilter(item.CID)">
                                        {{item.CategoryName}} <br><span>{{item.TotalRequiredPosition}} Jobs</span>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                    <!-- Hiring Companies Section -->
                    <div class="hiring-companies">
                        <h3>Hiring Companies</h3>
                        <div class="company-logos">
                            <img *ngFor="let item of Companies | slice:0:(showAll ? Companies.length : 10)" [src]="item.Companylogo ? HomeUrl + item.Companylogo : '../../../assets/img/logo.jpeg' "
                                 alt="Image" (click)="navigateToCompanyDetails(item.RID)" />
                        </div>
                        <div class="row">
                            <div class="col-lg-10 col-sm-6 col-8"></div>
                            <div class="col-lg-2 col-sm-6 col-4  float-end">
                                <button class="showall mmt3" *ngIf="!showAll && (Companies.length > 10 || 0)" (click)="navigateToCompanyProfile(RID)">Show All</button>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-lg-3">
                    <div #scrollContainer class="campus-direct-container no-scrollbar mmt3" style="max-height: 390px; overflow-y: auto;">
                        <div class="job-card  "
                             *ngFor="let item of AdsData; let i = index"
                             [ngStyle]="{ 'background-color': getCardColor(i) }">
                            <img src="{{HomeUrl+item.Image}}" style="border-radius:10px;" /><br />
                            <h3 class="company-name  text "><b>{{ item.CompanyName }}</b></h3>
                            <h6 class="job-title  text-black text mt-1">{{ item.JobTitle }}</h6>
                            <div class="text ">
                                <div class="margintop">
                                    <span class="text-black font_14 experience "><i class="fa-solid fa-suitcase mt-4"></i> {{ item.Experience }} Years</span>
                                </div>
                                <div class="margintop">
                                    <span class="text-black font_14 location "><i class="fa-solid fa-location-dot mt-4"></i> {{ item.Address }}</span>
                                </div>
                                <div class="margintop">
                                    <span class="text-black font_14 job-type "><i class="fa-solid fa-indian-rupee-sign mt-4"></i> {{ item.SalaryRange }}</span>
                                </div>
                                <div class="margintop">
                                    <span class="text-black font_14 job-type "><i class="fa-solid fa-envelope-open-text mt-4"></i> {{ item.Skills }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="main-container">
                        <div class="browse-jobs">
                            <div class="section-title">
                                <h2>What we offer</h2>
                                <sub>Offering the Best Deal</sub>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-12">
                    <div class="card-container1">
                        <div class="card1">
                            <div class="card-title1">Searching the Best Jobs</div>
                            <div class="card-text1">A powerful networking platform that allows you to connect directly with recruiters and other professionals in your field. It also offers jobs</div>
                            <a routerLink="/job" class="card-link1">Read More &raquo;</a>
                        </div>
                        <div class="card1">
                            <div class="card-title1">Apply for a Good Job</div>
                            <div class="card-text1">Applying for a good job involves several steps to ensure that your application stands out and effectively showcases your skills and experiences.</div>
                            <a routerLink="/job" class="card-link1">Read More &raquo;</a>
                        </div>
                        <div class="card1">
                            <div class="card-title1">More Quality Hires</div>
                            <div class="card-text1">To attract and retain more quality hires, organizations can implement several best practices throughout the hiring process.</div>
                            <a routerLink="/job" class="card-link1">Read More &raquo;</a>
                        </div>
                        <div class="card1">
                            <div class="card-title1 ">Post a Job Free</div>
                            <div class="card-text1">Posting a job for free can be an effective way to reach potential candidates without incurring costs</div>
                            <a routerLink="/job" class="card-link1">Read More &raquo;</a>
                        </div>
                    </div>
                </div>
            </div>
            <!--<div class="career-section">

                <h5>SEE HOW YOU CAN UP YOUR CAREER STATUS</h5>
                <h6>CAREER DEVELOPMENT & LEARNING</h6><br />


                <div class="container">
                    <div class="row g-4">
                        <div class="col-md-4" *ngFor="let item of ArticlesData; let i = index" [hidden]="i >= 3">
                            <div class="card">
                                <div class="article-card">
                                    <img src="{{HomeUrl + item.ArticleImg}}" />
                                    <h6>{{ item.ArticleName }}</h6>
                                    <a (click)="navigateToArticleDetails(item.AID)">Read Article</a>
                                </div>
                            </div>
                        </div>
                    </div>

                    <a (click)="navigateToArticles()" class="view-more">View More Articles</a>
                </div>
            </div>-->
        </div>
        <br />
        <div class="container">
            <div class="row">

                <div class="col-md-5">
                    <img src="../../../assets/img/images/jobseeker.png" alt="Njobs" />
                </div>
                <div class="col-md-7">
                    <div class="row">
                        <div class="col-lg-12">
                            <p>Top Recruiters</p>
                        </div>
                        <div class="col-lg-9">
                            <h2 class=""><strong>Top Talent Seekers</strong></h2>
                        </div>
                        <div class="col-lg-3">
                            <div class="text-center ">
                                <a class="text-decoration-underline" (click)="navigateToCompanyProfile(RID)">
                                    View All
                                </a>
                            </div>
                        </div>
                    </div>
                    <br /><br />
                    <div class="row">
                        <div class="col-lg-4" *ngFor="let item of Positions.slice(0, 6)">
                            <div class="image-container" (click)="navigateToCompanyDetails(item.RID)">
                                <div class="image">
                                    <img src="{{ HomeUrl + item.Companylogo }}"
                                         alt=""
                                         onerror="this.onerror=null; this.src='../../../assets/img/logo.jpeg';" />
                                </div>
                                <div class="image-text">
                                    <h5>{{ item.CompanyName }}</h5>
                                    <p>{{ item.TotalRequiredPosition }} Jobs</p>
                                </div>
                            </div>
                        </div>
                        <!--<div class="col-lg-3">
                        <div class="text-center mt-4">
                            <a class="text-decoration-underline" (click)="navigateToCompanyProfile()">
                                View All
                            </a>
                        </div>
                    </div>-->
                    </div>

                </div>
            </div>
        </div>


        <br />


        <div class="container">
            <div class="row">
                <div class="col-lg-6">
                    <div class="main-container">
                        <div class="browse-jobs">
                            <div class="section-title">
                                <h2>Post Jobs</h2>
                                <sub>Quick and easy way to advertise.</sub>
                            </div>
                        </div>
                    </div>
                    <div class="section">
                        <p class="fs_19 auto_font line_hight">
                            Effortlessly advertise your job openings and attract top talent.
                            Our platform makes it quick, simple, and effective to reach the right candidates.
                            Start connecting with qualified professionals today!
                        </p>
                        <ul class="features auto_font line_hight">
                            <li class="cardn">
                                <span class="auto_font fs_19 f_w600"> Hire for your company.</span><br />
                                <p class="subtext auto_font fs_19 f_w400 ">Reach top talent effortlessly and fill positions faster with our easy-to-use job posting platform.</p>
                            </li>
                            <li class="cardn">
                                <span class="auto_font fs_19 f_w600"> Daily outreach to qualified matches.</span>
                                <br />
                                <p class="subtext auto_font line_hight fs_19  f_w400">We connect you with the right candidates every day, ensuring the perfect fit for your team.</p>
                            </li>
                        </ul>
                        <div class="buttons">
                            <a href="#" class="btn-primary">Post a Job</a>
                            <a href="#" class="btn-secondary">Learn More</a>
                        </div>
                    </div>
                </div>


                <div class="col-lg-6">
                    <!-- Post Jobs Section -->
                    <div class="main-container">
                        <div class="browse-jobs">
                            <div class="section-title">
                                <h2>Talented Candidates</h2>
                                <sub>Get discovered by companies looking to hire remote.</sub>
                            </div>
                        </div>
                    </div>
                    <div class="section">
                        <p class="fs_19  f_w400 auto_font line_hight">
                            howcase your skills and get noticed by companies hiring remote professionals.
                            Our platform connects you with top employers looking for talent like yours.
                            Take the next step in your career today!
                        </p>
                        <ul class="features">
                            <li class="cardn">

                                <span class="auto_font fs_19 f_w600">  Get your profile listed.</span><br />

                                <p class="subtext fs_19  f_w400 auto_font">
                                    Boost your visibility to employers by listing your profile on our platform.
                                    Be the first choice for recruiters looking for talent like yours.
                                </p>
                            </li>
                            <li class="cardn">
                                <span class="auto_font fs_19 f_w600">   Customize your profile.</span>

                                <br />
                                <p class="subtext fs_19  f_w400 auto_font line_hight">
                                    Highlight your strengths with a tailored profile that reflects your unique abilities.
                                    Make a lasting impression and secure your next big opportunity.
                                </p>
                            </li>
                        </ul>
                        <div class="buttons">
                            <a routerLink="/job" class="btn-primary">Get Listed</a>
                            <a routerLink="/job" class="btn-secondary">Learn More</a>
                        </div>
                    </div>
                </div>

                <!-- Talented Candidates Section -->
                <div class="col-lg-6">

                </div>
            </div>
        </div>

        <br />

        <div class="container">
            <div class="banner">
                <div class="text-content">
                    <h4>EMPLOYERS</h4>
                    <h1>LOOKING TO POST A JOB?</h1>
                    <p>We have end-to-end solutions that can keep up with you and your standards.</p>
                    <button (click)="PostJobsFilter(RID)" *ngIf="!userDetails">Post a Job</button>
                </div>
                <div class="image-content">
                    <div class="circle-image">
                        <!--<p>LET'S <br><span>JOB IT</span> <br>UP.</p>-->
                    </div>
                    <!--<p>LET'S <br><span>JOB IT</span> <br>UP.</p>-->
                </div>
            </div>
        </div>
        <br />
        <div class="container">
            <div class="card pad">
                <div class="row">
                        <div class="col-lg-8">
                            <div class="">
                                <h3 style="color:#f43405;">Visit our Njobs Website on your Mobile</h3>
                                <p><b>Unlimited job applications | HRs contact you directly | Track your Applications.</b></p>
                            </div><br />
                            <div class="row">
                                <div class="col-lg-3 qrcode">
                                    <b>Scan QR code to access Njobs website</b>
                                </div>
                                <div class="col-lg-3">
                                    <img src="../../../assets/img/images/njobsqrcode.png" />
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4">
                            <div class="mobileimg">
                                <img src="../../../assets/img/images/mobilescreen.png" />
                            </div>
                        </div>
                </div>
            </div>
        </div>



    </div>
</div>

<!--<div id="top"></div>-->
