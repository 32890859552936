import { Component, HostListener, OnInit,  } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router'
import { GeneralService } from '../../Services/generalservice.service'

@Component({
  selector: 'app-job',
  templateUrl: './job.component.html',
  styleUrls: ['./job.component.scss']
})
export class JobComponent implements OnInit {
    arr: any;
    experienceOptions: string[] = [];
    skills: any;
    location: string = '';
    selectedExperience: string = '';
    experience: string | null = '';
    jobs: any[] = []; // Assuming the response is an array of job objects
    monthsGap: number;
    timeGap: string;
    LoginDetails: any; careerdata: any;
    specalizationdata: any; Experiencedata: any;
    CID: any; filterjobsdata: any; filterCompany: any;
    selectedCompany: string | null = null;
    CategoryName: any; SelectedDomain: string | null = null;
    Domainfilter: any;
    paginatedJobs: any[] = [];   // Jobs displayed on the current page
    currentPage: number = 1;     // Tracks the current page
    pageSize: number = 5;        // Number of jobs per page
    totalPages: number = 0;      // Total number of pages
    WorkingDomains: any; CompanyName: any;
    SelectedYear: any | null | '';
    applieddata: any; appliedPIDs: any;
    CategoryFilter: any; CompanyFilter: any;
    Fskills: any;
    RecruiterName: any;

    constructor(public route: ActivatedRoute, public router: Router, private General: GeneralService) {

        this.LoginDetails = JSON.parse(localStorage.getItem('userDetails'));
        this.Fskills = this.LoginDetails ?.KeySkills ?? 'undefined';
        this.CID = this.route.snapshot.paramMap.get('CID')
        if (this.CID) {
            this.CID = this.CID
        } else{
            this.CID =1
        }

    }

  
    ngOnInit() {
        this.appliedjob();
        this.getfilterjob();
        this.careerjob();
        this.specalization();
        this.Experience();
        for (let i = 1; i <= 30; i++) {
            this.experienceOptions.push(`${i} ${i === 1 ? 'Year' : 'Years'}`);
        }
        this.route.queryParams.subscribe(params => {
            this.skills = params['skills'] || '';
            this.location = params['location'] || '';
            this.selectedExperience = params['experience'] || '';
            this.findJobs()
        });
    }

   //search jobs by skills, location and Experiance
    findJobs() {
        debugger
        const UploadFile = new FormData();
        UploadFile.append("Param1", this.skills);
        UploadFile.append("Param2", this.location);
        UploadFile.append("Param3", this.selectedExperience);

        const url = "api/Njobs/Search_Jobs";

        this.General.PostData(url, UploadFile).then((data: any) => {
            debugger
            this.jobs = data; // Assuming data is an array of job objects
            if (this.jobs.length > 0) {
                this.totalPages = Math.ceil(this.jobs.length / this.pageSize); // Calculate total pages
                this.updatePaginatedJobs(); // Load the first set of jobs

                const lastJobDate = new Date(this.jobs[this.jobs.length - 1].CreatedDate);
                const currentDate = new Date();
                const monthDiff = this.calculateMonthDifference(lastJobDate, currentDate);

                if (monthDiff > 0) {
                    this.timeGap = `${monthDiff} Month(s)`;
                } else {
                    const dayDiff = this.calculateDayDifference(lastJobDate, currentDate);
                    if (dayDiff > 0) {
                        this.timeGap = `${dayDiff} Day(s)`;
                    } else {
                        this.timeGap = this.calculateTimeDifference(lastJobDate, currentDate);
                    }
                }
               
            } else {
                this.timeGap = 'No jobs found'; // No jobs available
            }
        }).catch((error) => {
            console.error("Error fetching jobs", error);
        });
    }



    // Fetch applied jobs only for logged-in users
    appliedjob() {
        if (!this.LoginDetails) {
            // User is not logged in, skip fetching applied jobs
            return;
        }

        const seekerID = this.LoginDetails.RegId;
        var uploadfile = new FormData();
        uploadfile.append("SeekerID", seekerID);

        var url = "api/Njobs/Get_Seekar_AppliedJobs";
        this.General.PostData(url, uploadfile).then(data => {
            this.applieddata = data;

            // Store applied PIDs in a set
            this.appliedPIDs = new Set(this.applieddata.map(job => job.PID));

            // Fetch all jobs and filter as needed
            this.findJobs();
            this.getfilterjob();
        });
    }


    // Helper function to calculate month difference
    private calculateMonthDifference(lastDate: Date, currentDate: Date): number {
        return (currentDate.getFullYear() - lastDate.getFullYear()) * 12 + (currentDate.getMonth() - lastDate.getMonth());
    }

    // Helper function to calculate day difference
    private calculateDayDifference(lastDate: Date, currentDate: Date): number {
        const diffTime = Math.abs(currentDate.getTime() - lastDate.getTime());
        return Math.floor(diffTime / (1000 * 60 * 60 * 24)); // Convert to days
    }

    // Helper function to calculate time difference in hours and minutes
    private calculateTimeDifference(lastDate: Date, currentDate: Date): string {
        const diffTime = Math.abs(currentDate.getTime() - lastDate.getTime());
        const hours = Math.floor(diffTime / (1000 * 60 * 60)); // Convert to hours
        const minutes = Math.floor((diffTime % (1000 * 60 * 60)) / (1000 * 60)); // Convert to minutes

        return `${hours} hr(s), ${minutes} min(s)`;
    }

    // Helper function to update the displayed jobs based on the current page
    updatePaginatedJobs() {
        debugger
        const startIndex = (this.currentPage - 1) * this.pageSize;
        const endIndex = startIndex + this.pageSize;
      //  this.paginatedJobs = this.jobs.slice(startIndex, endIndex);

        if (!this.LoginDetails) {
            this.paginatedJobs = this.jobs.slice(startIndex, endIndex);
            return;
        } else if(this.LoginDetails) {

            // Extract the first skill fro// this.Skills, which is a comma-separated string.
            const cskill = this.Fskills
            const firstSkill = cskill.split(',')[0].trim();

            // Filter jobs based on the first skill.
            const filteredJobs = this.jobs.filter(item => item.RequiredSkills.split(',')[0].trim().toLowerCase() === firstSkill.toLowerCase()
            );

            // Apply pagination to the filtered jobs.
            this.paginatedJobs = filteredJobs.slice(startIndex, endIndex);
            return;
        }


       
    }

    // Navigate to the next page
    nextPage() {
        debugger
        if (this.currentPage < this.totalPages) {
            this.currentPage++;
            this.updatePaginatedJobs();
            this.updatecategory();
        }
    }

    // Navigate to the previous page
    previousPage() {
        debugger
        if (this.currentPage > 1) {
            this.currentPage--;
            this.updatePaginatedJobs();
            this.updatecategory();
        }
    }

    updatecategory() {
        const startIndex = (this.currentPage - 1) * this.pageSize;
        const endIndex = startIndex + this.pageSize;
        this.CategoryFilter = this.filterjobsdata.slice(startIndex, endIndex)
    }

    compantnextPage() {
        debugger
        if (this.currentPage < this.totalPages) {
            this.currentPage++;
            this.updatecompany();
        }
    }

    companyPrevious() {
        if (this.currentPage > 1) {
            this.currentPage--;
            this.updatecompany();
        }
    }

    updatecompany() {
        const startIndex = (this.currentPage - 1) * this.pageSize;
        const endIndex = startIndex + this.pageSize;
        this.CompanyFilter = this.filterCompany.slice(startIndex, endIndex)
    }

    //filter button
    isFilterActive = false;

    toggleFilter(): void {
        this.isFilterActive = !this.isFilterActive;
    }

    closeFilter(): void {
        this.isFilterActive = false;
    }

    @HostListener('document:keydown.escape')
    onEscapePress() {
        this.closeFilter();
    }


    //After applying job by JobSeeker, that application is inserting method
    applujob(job: any) {
        if (!this.LoginDetails) {
            this.General.ShowAlert("Alert", 'You are not logged in. Please log in to apply for jobs.', 'Alert');
            this.router.navigate(['/Login']);
            return;
        }

        if (this.LoginDetails.ApprovalStatus === 1) {
            this.General.ShowAlert("ERROR", 'You do not have permission to apply for jobs. Admin approval is required.', 'error');
            return;
        }

        // Apply for job logic
        this.arr = [{
            SeekerID: this.LoginDetails.RegId,
            P_ID: job.PID,
            R_ID: job.RID,
            ActionStatus: 1,
            AppliedStatus: 1
        }];
        var uploadfile = new FormData();
        uploadfile.append("xml", JSON.stringify(this.arr));

        var url = "api/Njobs/Insert_Applied_Job";
        this.General.PostData(url, uploadfile).then(data => {
            if (data === "SUCCESS") {
                this.General.ShowAlert("SUCCESS", 'You have successfully applied for this job.', 'success');
                window.location.reload();
            } else {
                this.General.ShowAlert("ERROR", 'Something went wrong. Please try again later.', 'error');
            }
        });
    }

    careerjob() {
        var uploadfile = new FormData();
        uploadfile.append("Param",'1');
        var url = "api/Njobs/Get_Jobs_Specialization";
        this.General.PostData(url, uploadfile).then(data => {
            this.careerdata = data;
        })
    }

    specalization() {
        var uploadfile = new FormData();
        uploadfile.append("Param", '2');
        var url = "api/Njobs/Get_Jobs_Specialization";
        this.General.PostData(url, uploadfile).then(data => {
            this.specalizationdata = data
        })
    }

    Experience() {
        var uploadfile = new FormData();
        uploadfile.append("Param", '3');
        var url = "api/Njobs/Get_Jobs_Specialization";
        this.General.PostData(url, uploadfile).then(data => {
            this.Experiencedata = data;
        })
    }


    //categories can get
    getfilterjob() {
        debugger
        var uploadfile = new FormData();
        uploadfile.append('Param1', this.CID);
        var url = "api/Njobs/Get_Category_Jobs_Filter";
        this.General.PostData(url, uploadfile).then(data => {
            this.filterjobsdata = data;
            this.CategoryName = this.filterjobsdata[0].CategoryName
            if (this.filterjobsdata.length > 0) {
                this.totalPages = Math.ceil(this.filterjobsdata.length / this.pageSize);
                this.updatecategory();
            } else {

            }
        })

    }


    //filter by Company Names
    Companyfilter(CompanyName) {
        debugger
        var uploadfile = new FormData();
        uploadfile.append('Param1', CompanyName);
        uploadfile.append('Param2', this.SelectedDomain || '');
        uploadfile.append('Param3', this.SelectedYear || '');
        var url = "api/Njobs/Get_jobs_BasedOn_CompanyName";
        this.General.PostData(url, uploadfile).then(data => {
            this.filterCompany = data;
            this.CompanyName = this.filterCompany[0].CompanyName
            if (this.filterCompany > 0) {
                this.totalPages = Math.ceil(this.filterCompany.length / this.pageSize);
                this.updatecompany();
            }
        })

    }

    onCompanySelect(RecruiterName: string) {
        // Toggle the selected company
        if (this.selectedCompany === RecruiterName) {
            // If the same company is clicked again, deselect it
            this.selectedCompany = null;
            this.filterCompany = null;
        } else {
            // Select the new company and fetch the data
            this.selectedCompany = RecruiterName;
            this.Companyfilter(RecruiterName);
        }
    }

    onSelectWorking(domain: string) {
        if (this.SelectedDomain === domain) {
            this.SelectedDomain = null;
           // this.filterCompany = null;
        } else {
            this.SelectedDomain = domain;
            this.Companyfilter(this.selectedCompany);
        }
    }

    onSelectedyear(Year: string) {
        if (this.SelectedYear === Year) {
            this.SelectedYear = null;
            //  this.filterCompany = null;
        } else {
            this.SelectedYear = Year;
            this.Companyfilter(this.selectedCompany);
        }
    }

    domainfilter(Domain) {
        var uploadfile = new FormData();
        uploadfile.append('Param1', Domain);
        var url = "api/Njobs/Get_Jobson_workingdomain";
        this.General.PostData(url, uploadfile).then(data => {
            this.Domainfilter = data;
            this.WorkingDomains = this.Domainfilter[0].WorkingDomains
        })

    }


    //routing by after login candidate only can submit his resume 
    uploadresume() {
        if (!this.LoginDetails) {
            this.router.navigate(['/Login']);
        } else {
            this.router.navigate(['/useraccount']);
        }
    }

}
