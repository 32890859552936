import { Component, HostListener, TemplateRef, ViewChild } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormControl, AbstractControl, FormBuilder, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
//import { Router } from '@angular/router';
import { GeneralService } from '../../Services/generalservice.service';
import { HttpHeaders } from '@angular/common/http';
import Swal from 'sweetalert2';
import { ChangeDetectorRef } from '@angular/core';
import { retry } from 'rxjs/operators';
declare var $: any;
import { ElementRef, Renderer2 } from '@angular/core';
@Component({
    selector: 'app-company-account',
    templateUrl: './company-account.component.html',
    styleUrls: ['./company-account.component.scss']
})
export class CompanyAccountComponent {
    @ViewChild('myModal2') modalTemplate: TemplateRef<any>;
    EnquiryAry: any = [];
    public form: FormGroup;
    admin: any;
    TemplateId: any;
    MessageText: any;
    contactForm: FormsModule;
    HomeURL: any; value: any;
    token: any;
    deptList: any[] = [];
    Categorylist: any;
    arr: any;
    Citylist: any;
    JobTypelist: any;
    Languageslist: any;
    Skillslist: any;
    SalaryRangelist: any;
    Statelist: any;
    Districtlist: any;
    loginDet: any;
    loginDetails: any;
    districts1: any;
    Cities1: any;
    City: any;
    Cities: any;
    districts: any;
    statedata: any;
    Experiencelist: any;
    Qualificationlist: any;
    Postjobs: any;
    isDropdownOpen = false;
    CompanyName: any;
    RecruiterName: any;
    updatedata: any; Profile: any; MobileNo: any; Password: any;
    Email: any; Mobile: any; Website: any; Address: any; AboutInfo: any;
    arr2: any[];
    arr3: any;
    PID: any;
    mnac: any;
    mnac1: any;
    ProfileImagedata: any; imagefile2: any; ProfileImage: any; Profiledata: any;
    jobdata: any;
    cdr: any;
    pjobdata: any;
    Sjobdata: any;
    Rjobdata: any;
    Selectjobdata: any;
    Rejectjobdata: any;
    cmpnylogo: any; TransctionID: any; Subscribedata: any;
    Packagedata: any;
    profileupdate: any;
    newSkill: any;
    displayAddSkillPopup = false;
    public Rskills: any;
    selectedTab = 'tab2';
    selectedSkills: any;
    constructor(private elementRef: ElementRef, private renderer: Renderer2, public generalService: GeneralService,
        private http: HttpClient, private fb: FormBuilder, public Rm: ReactiveFormsModule,
        public router: Router, public route: ActivatedRoute) {
        debugger
        this.PID = this.route.snapshot.paramMap.get("PID");
        this.loginDet = localStorage.getItem("LoginDetails");
        this.loginDetails = JSON.parse(this.loginDet)
        this.selectedTab = localStorage.getItem('selectedTab') || 'tab1';
         this.Website = this.loginDetails[0].Website || '',
            this.Address = this.loginDetails[0].Address || '',
            this.AboutInfo = this.loginDetails[0].AboutInfo || ''
        
        console.log('pid', this.PID)

        this.ProfileImage = this.loginDetails.Companylogo || '';
        this.http.get('../assets/WebService.json').subscribe((data: any) => {
            this.HomeURL = data.Webservice;
            
        });
        this.form = this.fb.group({
            Jobtitle: ['', Validators.required], // Set up form control for Categoryname with required validation
           // RecruiterName: ['', Validators.required], // Set up form control for Categoryname with required validation
            CompanyName: ['', Validators.required], // Set up form control for Categoryname with required validation
            CategoryName: ['', Validators.required], // Set up form control for Categoryname with required validation
            CityName: ['', Validators.required], // Set up form control for Categoryname with required validation
            RequiredPosition: ['', Validators.required], // Set up form control for Categoryname with required validation
            TypeName: ['', Validators.required], // Set up form control for Categoryname with required validation
            Languages: ['', Validators.required], // Set up form control for Categoryname with required validation
            SalaryRange: ['', Validators.required], // Set up form control for Categoryname with required validation
            Skills: [this.selectedSkills], // Set up form control for Categoryname with required validation
            Experience: ['', Validators.required], // Set up form control for Categoryname with required validation
            Qualification: ['', Validators.required], // Set up form control for Categoryname with required validation
            JobDescription: ['', Validators.required] // Set up form control for Categoryname with required validation
        });
      

    }


    ngOnInit() {
        this.getProfileimage();
        this.getPackages();
        this.GetCategory();
        this.GetCity();
        this.GetJobType();
        this.GetLanguages();
        this.GetSalaryRange();
        this.GetSkills();
        this.GetExperience();
        this.GetQualification();
        //this.GetPostjobs();
        this.GetProfile();
        this.Companyjobs();
        this.Getpendingjob();
        this.Getrejectjob();
        this.Getselectjob();

    }

    jobtab: string = 'Postjobs'; // Set default tab to 'companyDetail'
    currentTab: string = 'companyDetail'; // Set default tab to 'companyDetail'
    selecttab: string = 'pendingDetail'; // Set default tab to 'selectedDetail'

    switchjobs(tabName: string): void {
        debugger
        this.jobtab = tabName;
        this.Companyjobs();
    }
    switchTab(tabName: string): void {
        this.currentTab = tabName; // Change the active tab
    }
    switchTab1(tabName: string): void {
        this.selecttab = tabName; // Change the active tab
    }
  
    toggleDropdown() {
        this.isDropdownOpen = !this.isDropdownOpen;
    }

    closeDropdown() {
        this.isDropdownOpen = false;
    }

    jobEntries = [
        { id: 'job1', title: 'Manage Jobs', position: 'Java Developer', postedBy: 'John(HR)', datePosted: '3 August 2018' },
        { id: 'job2', title: '', position: 'Python Developer', postedBy: 'Allen(HR)', datePosted: '3 August 2018' },
        { id: 'job3', title: '', position: 'SQL Developer', postedBy: 'Charan(HR)', datePosted: '3 August 2018' },
    ];

    //@HostListener('document:click', ['$event'])




    open() {
        debugger
        const modal3 = this.elementRef.nativeElement.querySelector('#myModal2');
        this.renderer.setStyle(modal3, 'display', 'block');  // Show the modal
    }

    Close() {
        const modal3 = this.elementRef.nativeElement.querySelector('#myModal2');
        this.renderer.setStyle(modal3, 'display', 'none');  // Hide the modal
    }


    // Adds the new skill to the skills list
    addSkill(val:any): void {
        debugger
        this.arr3 = [];
        this.arr3.push({

          
            Skills: val,
            CreatedBy: this.loginDetails[0].RID
           
            
        })
        var uploadfile = new FormData();
        uploadfile.append("Param", JSON.stringify(this.arr3));
        uploadfile.append("Flag", '1');
        var url = "api/Njobs/SkillMaster_Crud";
        this.generalService.PostData(url, uploadfile).then(data => {
            debugger
            if (data = 'SUCCESS') {
                this.generalService.ShowAlert("SUCCESS", 'Your skills add Succesfully', 'success');
                const modal3 = this.elementRef.nativeElement.querySelector('#myModal2');
                this.renderer.setStyle(modal3, 'display', 'none');
                this.router.navigate(['/company-account']);
                window.location.reload();
         
            } else {
                this.generalService.ShowAlert("ERROR", 'Some Thing Went Wrong', 'error');
            }

        })
    }
    

    GetProfile() {       
        this.arr = [];
        this.arr.push({
            RID: this.loginDetails[0].RID
        });
        var UploadFile = new FormData();
        UploadFile.append("Param", JSON.stringify(this.arr));
        UploadFile.append("Flag", '4');

        var url = "api/Njobs/RecruiterRegister_Crud";
        this.generalService.PostData(url, UploadFile).then((data: any) => {
           
            this.Profile = data;
         
            this.RecruiterName = this.Profile[0].RecruiterName,
              this.CompanyName = this.Profile[0].CompanyName,
              this.Email = this.Profile[0].Email,        
                  this.MobileNo = this.Profile[0].MobileNo,
                  this.Password = this.Profile[0].Password
            
           
         
            
        },
            err => {
                this.generalService.ShowAlert('ERROR', 'Something went wrong, please try again later', 'error');
            });
    }


    downloadResume(fileName: string, event: any) {
        debugger
        this.Updatestatusview(event.JID)
        const pdfUrl = this.HomeURL + event.Resume;  // Path to the PDF file

        // Open PDF in a new tab (to view the PDF)
        window.open(pdfUrl, '_blank');

        // Download PDF (prompt download)
        
    }

    onFileSelected(event: any) {
   
        const file: File = event.target.files[0];

        // Check if a file is selected
        if (!file) {
            alert('No file selected.');
            return;
        }

        // Extract the file extension and convert it to lowercase
        const ext = file.name.split('.').pop().toLowerCase();

        // Define allowed file extensions
        const allowedExtensions = ['jpg', 'jpeg', 'png', 'jfif', 'avi', 'mov', 'mp4'];

        // Validate the file type
        if (allowedExtensions.includes(ext)) {
            const formData: FormData = new FormData();
            formData.append('PostedFile', file, file.name);

            // Define the API endpoint for uploading
            const url = 'api/Njobs/UploadProfileImage';

            // Call the service to upload the file
            this.generalService.PostData(url, formData).then((data: any) => {
                if (data) {
                    this.imagefile2 = this.HomeURL + data; // Update the image source
                    this.ProfileImage = data; // Store the profile image path
                    this.updateprofileimage(this.ProfileImage);
                } else {
                    alert('Upload failed. Please try again.');
                }
            }).catch(error => {
                console.error('Error uploading file:', error);
                alert('An error occurred while uploading the file. Please try again.');
            });
        } else {
            alert('Only jpg/jpeg, png, avi, mov, and mp4 files are allowed!');
        }
    }

    playAudio(audioUrl: any) {
        const audioPath = this.HomeURL + audioUrl.AudioRecord;

        // Open a new tab
        const newTab = window.open(audioPath, '_blank');

        if (newTab) {
            // Inject an HTML audio element to play in the new tab
            newTab.document.write(`
            <html>
                <head><title>Audio Playback</title></head>
                <body style="margin: 0; padding: 0; display: flex; justify-content: center; align-items: center; height: 100vh;">
                    <audio controls autoplay style="width: 30%;">
                        <source src="${audioPath}" type="audio/mpeg">
                        Your browser does not support the audio element.
                    </audio>
                </body>
            </html>
        `);

            newTab.document.close();
        } else {
            console.error('Failed to open new tab for audio playback');
        }
    }


    // Function to play video in a new window or tab
    playVideo(videoUrl: any) {
   
        // Combine the base URL and the video record path to form the full video URL
        const videolink = this.HomeURL + videoUrl.VideoRecord;

        // Check if the video URL is valid (i.e., non-empty and properly formed)
        if (videolink && videolink.trim() !== '') {
            // Create a new window or tab
            const newTab = window.open('', '_blank');

            if (newTab) {
                // Create the HTML content to embed the video in the new tab
                newTab.document.write(`
                <!DOCTYPE html>
                <html lang="en">
                <head>
                    <meta charset="UTF-8">
                    <meta name="viewport" content="width=device-width, initial-scale=1.0">
                    <title>Video Player</title>
                </head>
                <body>
                    <video controls autoplay style="width: 40%; height: auto;">
                        <source src="${videolink}" type="video/mp4">
                        Your browser does not support the video tag.
                    </video>
                </body>
                </html>
            `);
                newTab.document.close();  // Close the document after writing
            }
        } else {
            console.error('Invalid video URL:', videolink);
        }
    }




    Updatestatusview(JID) {
        debugger
        var uploadfile = new FormData();
        uploadfile.append('Param1', JID);
        uploadfile.append('Param2', '4');
        var url = "api/Njobs/NJ_UpdateStatus";
        this.generalService.PostData(url, uploadfile).then((data: any) => {         
           
        });
    }
    UpdatestatusSelect(candidate) {
        debugger
        var uploadfile = new FormData();
        uploadfile.append('Param1', candidate.JID);
        uploadfile.append('Param2','2');
        var url = "api/Njobs/NJ_UpdateStatus";
        this.generalService.PostData(url, uploadfile).then((data: any) => {
              if (data === 'SUCCESS') {
           
                  this.generalService.ShowAlert("SUCCESS", 'Selected candidate successfully.', 'success');
                  window.location.reload();
        } else {
           
            this.generalService.ShowAlert("ERROR", 'Failed to update status for the selected candidate.', 'error');
        }
    }).catch(err => {
       
        this.generalService.ShowAlert("ERROR", 'Something went wrong while updating the status.', 'error');
    });
}
           

    
    UpdatestatusReject(candidate) {
        debugger
        var uploadfile = new FormData();
        uploadfile.append('Param1', candidate.JID);
        uploadfile.append('Param2', '3');
        var url = "api/Njobs/NJ_UpdateStatus";
        this.generalService.PostData(url, uploadfile).then((data: any) => {
            if (data === 'SUCCESS') {
                // Show a success alert message
                this.generalService.ShowAlert("SUCCESS", 'Rejected candidate successfully.', 'success');
                window.location.reload();
            }
        });
    }
    updateprofile() {
       
        debugger

        this.selectedTab = 'tab2';       
        localStorage.setItem('selectedTab', this.selectedTab);

        this.arr3 = [];
        this.arr3.push({
           
            RID: this.loginDetails[0].RID,
            CompanyName: this.CompanyName,
            Email: this.Email,
            Website: this.Website,
            Address: this.Address,
            AboutInfo: this.AboutInfo,
            MobileNo: this.MobileNo,
            Password: this.Password,
            //Companylogo:this.Profiledata[0].Companylogo,
            //PID: this.Postjobs[0].PID
        })
        var uploadfile = new FormData();
        uploadfile.append("Param", JSON.stringify(this.arr3));
        uploadfile.append("Flag", '2');
        var url = "api/Njobs/RecruiterRegister_Crud";
        this.generalService.PostData(url, uploadfile).then(data => {
          debugger
            if (data = 'SUCCESS') {
                this.generalService.ShowAlert("SUCCESS", 'Your Profile Update Succesfully', 'success');             
                this.selectedTab = 'tab2';
                this.login();
                
               // this.router.navigate(['/company-account']);
            } else {
                this.generalService.ShowAlert("ERROR", 'Some Thing Went Wrong', 'error');
            }

        })
    }
    selectedFileName: string;
   

    updateprofileimage(imag) {
     
      
        this.arr = [];
        this.arr.push({
            RID: this.loginDetails[0].RID,
            Companylogo:imag,
        })
        var uploadfile = new FormData();
        uploadfile.append("Param", JSON.stringify(this.arr));
        uploadfile.append("Flag", '7');
        var url = "api/Njobs/RecruiterRegister_Crud";
        this.generalService.PostData(url, uploadfile).then(data => {
          
            if (data == 'SUCCESS') {
                this.ProfileImagedata = JSON.stringify(this.arr[0]);
                localStorage.setItem('CompanyLogos', this.ProfileImagedata);
                this.generalService.ShowAlert("Update", 'Your Profile Updated Succesfully', 'update')
                window.location.reload();
            } else {
                this.generalService.ShowAlert("ERROR", 'Some Thing Went Wrong', 'error')
            }

        })

    }

    getProfileimage() {
        
        var uploadfile = new FormData();
        uploadfile.append("Param", this.loginDetails[0].RID);
        var url = "api/Njobs/Get_CompanyImage";
        this.generalService.PostData(url, uploadfile).then(data => {
           
            this.Profiledata = data;
            this.cmpnylogo = this.HomeURL+this.Profiledata[0].Companylogo
        });

    }

  
    Getpendingjob() {
        debugger
       
        var uploadfile = new FormData();
        uploadfile.append("Param1", this.loginDetails[0].RID);
        var url = "api/Njobs/NJ_Get_JobApplyDetailsBasedonCompanyId";
        this.generalService.PostData(url, uploadfile).then(data => {
            debugger
            this.pjobdata = data;    

            this.jobdata = this.pjobdata.filter(item => item.ActionStatus === 1 || item.ActionStatus === 4);
        })
    }

    Getselectjob() {
        debugger
        var uploadfile = new FormData();
        uploadfile.append("Param1", this.loginDetails[0].RID);
        var url = "api/Njobs/NJ_Get_JobApplyDetailsBasedonCompanyId";
        this.generalService.PostData(url, uploadfile).then(data => {
            debugger
            this.Selectjobdata = data;
            this.Sjobdata = this.Selectjobdata.filter(item => item.ActionStatus === 2 || item.ActionStatus === 4);
           
        })
    }
    Getrejectjob() {
        debugger
        var uploadfile = new FormData();
        uploadfile.append("Param1", this.loginDetails[0].RID);
        var url = "api/Njobs/NJ_Get_JobApplyDetailsBasedonCompanyId";
        this.generalService.PostData(url, uploadfile).then(data => {
            debugger
            this.Rejectjobdata = data;
            this.Rjobdata = this.Rejectjobdata.filter(item => item.ActionStatus === 3 || item.ActionStatus === 4);
           
        })
    }


    GetCategory() {

        this.arr = [];
        this.arr.push({});

        var url = "api/Njobs/Get_Category";
        this.generalService.GetData(url).then(data => {

            this.Categorylist = data;
        }, err => {
            this.generalService.ShowAlert('ERROR', 'Something went wrong', 'error');
        });
    }

    searchCont(event) {

        let filtered: any = [];
        let query = event.query;
        {
            this.deptList = [];
            for (let i = 0; i < this.Categorylist.length; i++) {
                let type = this.Categorylist[i];
                if (type.CategoryName.toLowerCase().indexOf(query.toLowerCase()) == 0) {
                    filtered.push(type);
                    //   this.ButtonText = 'Add';
                }
            }
            this.deptList = filtered;
        }
    }

 

    GetJobType() {

        this.arr = [];
        this.arr.push({});

        var url = "api/Njobs/Get_JobTypes";
        this.generalService.GetData(url).then(data => {

            this.JobTypelist = data;
        }, err => {
            this.generalService.ShowAlert('ERROR', 'Something went wrong', 'error');
        });
    }

    searchCont2(event) {

        let filtered: any = [];
        let query = event.query;
        {
            this.deptList = [];
            for (let i = 0; i < this.JobTypelist.length; i++) {
                let type = this.JobTypelist[i];
                if (type.TypeName.toLowerCase().indexOf(query.toLowerCase()) == 0) {
                    filtered.push(type);
                    //   this.ButtonText = 'Add';
                }
            }
            this.deptList = filtered;
        }
    }

    GetLanguages() {

        this.arr = [];
        this.arr.push({});

        var url = "api/Njobs/Get_Languages";
        this.generalService.GetData(url).then(data => {

            this.Languageslist = data;
        }, err => {
            this.generalService.ShowAlert('ERROR', 'Something went wrong', 'error');
        });
    }

    searchCont3(event) {

        let filtered: any = [];
        let query = event.query;
        {
            this.deptList = [];
            for (let i = 0; i < this.Languageslist.length; i++) {
                let type = this.Languageslist[i];
                if (type.Languages.toLowerCase().indexOf(query.toLowerCase()) == 0) {
                    filtered.push(type);
                    //   this.ButtonText = 'Add';
                }
            }
            this.deptList = filtered;
        }
    }

    GetSkills() {

        this.arr = [];
        this.arr.push({});

        var url = "api/Njobs/Get_Skills";
        this.generalService.GetData(url).then(data => {

            this.Skillslist = data;
        }, err => {
            this.generalService.ShowAlert('ERROR', 'Something went wrong', 'error');
        });
    }

    searchCont4(event) {

        let filtered: any = [];
        let query = event.query;
        {
            this.deptList = [];
            for (let i = 0; i < this.Skillslist.length; i++) {
                let type = this.Skillslist[i];
                if (type.Skills.toLowerCase().indexOf(query.toLowerCase()) == 0) {
                    filtered.push(type);
                    //   this.ButtonText = 'Add';
                }
            }
            this.deptList = filtered;
        }
    }

    GetSalaryRange() {

        this.arr = [];
        this.arr.push({});

        var url = "api/Njobs/Get_SalaryRange";
        this.generalService.GetData(url).then(data => {

            this.SalaryRangelist = data;
        }, err => {
            this.generalService.ShowAlert('ERROR', 'Something went wrong', 'error');
        });
    }

    searchCont5(event) {

        let filtered: any = [];
        let query = event.query;
        {
            this.deptList = [];
            for (let i = 0; i < this.SalaryRangelist.length; i++) {
                let type = this.SalaryRangelist[i];
                if (type.SalaryRange.toLowerCase().indexOf(query.toLowerCase()) == 0) {
                    filtered.push(type);
                    //   this.ButtonText = 'Add';
                }
            }
            this.deptList = filtered;
        }
    }


    GetCity() {

        this.arr = [];
        this.arr.push({});

        var url = "api/Njobs/Get_City";
        this.generalService.GetData(url).then(data => {

            this.Citylist = data;
        }, err => {
            this.generalService.ShowAlert('ERROR', 'Something went wrong', 'error');
        });
    }

    searchCont1(event) {

        let filtered: any = [];
        let query = event.query;
        {
            this.deptList = [];
            for (let i = 0; i < this.Citylist.length; i++) {
                let type = this.Citylist[i];
                if (type.CityName.toLowerCase().indexOf(query.toLowerCase()) == 0) {
                    filtered.push(type);
                    //   this.ButtonText = 'Add';
                }
            }
            this.deptList = filtered;
        }
    }

    GetExperience() {

        this.arr = [];
        this.arr.push({});

        var url = "api/Njobs/Get_Experience_Master";
        this.generalService.GetData(url).then(data => {

            this.Experiencelist = data;
        }, err => {
            this.generalService.ShowAlert('ERROR', 'Something went wrong', 'error');
        });
    }

    searchCont6(event) {

        let filtered: any = [];
        let query = event.query;
        {
            this.deptList = [];
            for (let i = 0; i < this.Experiencelist.length; i++) {
                let type = this.Experiencelist[i];
                if (type.Experience.toLowerCase().indexOf(query.toLowerCase()) == 0) {
                    filtered.push(type);
                    //   this.ButtonText = 'Add';
                }
            }
            this.deptList = filtered;
        }
    }
    
    GetQualification() {

        this.arr = [];
        this.arr.push({});

        var url = "api/Njobs/Get_Qualification";
        this.generalService.GetData(url).then(data => {

            this.Qualificationlist = data;
        }, err => {
            this.generalService.ShowAlert('ERROR', 'Something went wrong', 'error');
        });
    }

    searchCont7(event) {

        let filtered: any = [];
        let query = event.query;
        {
            this.deptList = [];
            for (let i = 0; i < this.Qualificationlist.length; i++) {
                let type = this.Qualificationlist[i];
                if (type.Qualification.toLowerCase().indexOf(query.toLowerCase()) == 0) {
                    filtered.push(type);
                    //   this.ButtonText = 'Add';
                }
            }
            this.deptList = filtered;
        }
    }

    onSubmit(val) {
        debugger

        if (this.loginDetails[0].ApprovalStatus === '1') {
            this.generalService.ShowAlert("ERROR", 'You do not have permission. admin to to accept your profile,please contact admin.', 'error');
            return;
        }
        this.arr = [];
        const selectedSkills = val.Skills.map(skill => skill.Skills).join(', ');
        this.arr.push({
            RecruiterName: this.loginDetails[0].RecruiterName,
            RID: this.loginDetails[0].RID,
            CompanyName: val.CompanyName,
            WorkingDomains: val.Jobtitle,
            RequiredPosition: val.RequiredPosition,
            RequiredSkills: selectedSkills,
            CID: val.CategoryName.CID,
            JTID: val.TypeName.JTID,
            LID: val.Languages.LID,
            SID: val.SalaryRange.SID,
            CityId: val.CityName.CityId,
            EXPID: val.Experience.EXPID,
            QID: val.Qualification.QID,
            JobDescription: val.JobDescription
        });
        const UploadFile = new FormData();
        UploadFile.append("Param", JSON.stringify(this.arr));
        UploadFile.append("Flag", '1');

        const url = "api/Njobs/NJ_PostJobs_Crud";
        this.generalService.PostData(url, UploadFile).then(data => {
            if (data === 'SUCCESS') {
                this.generalService.ShowAlert("SUCCESS", 'Your JobPost Completed Successfully', 'success');
              
               window.location.reload();
            } else {
                this.generalService.ShowAlert("ERROR", 'Something went wrong', 'error');
            }
        });
    }
    login() {
        debugger
        const uploadfile = new FormData();
        uploadfile.append("MobileNo", this.loginDetails[0].MobileNo);
        uploadfile.append("Password", this.loginDetails[0].Password);

        const url = "api/Njobs/RecruiterLogin";
        this.generalService.PostData(url, uploadfile).then(data => {
            if (data != null) { // Ensure login data is available

                localStorage.setItem("LoginDetails", JSON.stringify(data));
                this.router.navigate(['/company-account']).then(() => {
                   window.location.reload();
                });
            } else {
                // Handle login error if login data is not returned
                this.generalService.ShowAlert("ERROR", 'Login failed. Please try again.', 'error');
            }
        });
    }
    //GetPostjobs() {
    //    debugger
    //    this.arr = [];
    //    this.arr.push({
    //        RID: this.loginDetails[0].RID
    //     });
    //        var UploadFile = new FormData();
    //        UploadFile.append("Param", JSON.stringify(this.arr));
    //        UploadFile.append("Flag", '4');

    //    var url = "api/Njobs/NJ_PostJobs_Crud";
    //    this.generalService.PostData(url, UploadFile).then((data: any) => {
    //        debugger
    //        this.Postjobs = data;
    //        },
    //        err => {
    //            this.generalService.ShowAlert('ERROR', 'Something went wrong, please try again later', 'error');
    //        });
    //}


    Companyjobs() {
        debugger
        var uploadfile = new FormData();
        uploadfile.append('Param1', this.CompanyName);
        var url = "api/Njobs/Get_jobs_BasedOn_CompanyName";
        this.generalService.PostData(url, uploadfile).then((data: any) => {
            debugger
            this.Postjobs = data;
            //this.CompanyName = {
            //    CompanyName: this.Postjobs[0].CompanyName,
            //    PID: this.Postjobs[0].PID // Assuming GSTID is available in the response
            //};
            this.CompanyName = {
                CompanyName: this.Postjobs[0].CompanyName,
                PID: this.Postjobs[0].PID
            };
        });
    }
    Editform(PID: any) {

        this.router.navigate(['/Managejobs', { PID: PID }]);
    }

    DeleteCadidates(JID) {
        debugger;
        Swal.fire({
            title: 'Alert',
            text: 'Are you sure to delete this Candidate ?',
            showCancelButton: true,
            //icon: 'warning',
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'No, keep it'
        }).then((data1) => {
            if (data1.value == true) {
               
                var UploadFile = new FormData();
                UploadFile.append('Param1', JID);
               
                var url = "api/Njobs/Delete_AppliedJobs_basedonJID";
                this.generalService.PostData(url, UploadFile).then((data: any) => {
                    debugger
                    if (data === 'SUCCESS') {
                        this.generalService.ShowAlert("SUCCESS", 'Candidate Deleted Successfully', 'success');
                        window.location.reload();
                    } else {
                        this.generalService.ShowAlert("ERROR", 'Something went wrong', 'error');
                    }
                });
            }
        });
    }
    Delete(PID) {
        debugger;
        Swal.fire({
            title: 'Alert',
            text: 'Are you sure to delete this JobPost ?',
            showCancelButton: true,
            //icon: 'warning',
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'No, keep it'
        }).then((data1) => {
            if (data1.value == true) {

               
                this.arr2 = [];
                this.arr2.push({
                    PID: PID,
                });
                var UploadFile = new FormData();
                UploadFile.append('Param', JSON.stringify(this.arr2));
                UploadFile.append("Flag", '3');
                var url = "api/Njobs/NJ_PostJobs_Crud";
                this.generalService.PostData(url, UploadFile).then((data: any) => {
                    debugger
                    if (data === 'SUCCESS') {
                        this.generalService.ShowAlert("SUCCESS", 'Your JobPost Deleted Successfully', 'success');
                        window.location.reload();
                    } else {
                        this.generalService.ShowAlert("ERROR", 'Something went wrong', 'error');
                    }
                });
            }
        });
    }
    download(pdfpath: string | null): void {
        debugger
        console.log('Download clicked:', pdfpath); // Log to check if the function is called
        if (pdfpath) {
            const absoluteUrl: string = `http://localhost:4200/${pdfpath}`;
            console.log('Absolute URL:', absoluteUrl); // Log the constructed URL

            const link = document.createElement('a');
            link.href = absoluteUrl;
            link.setAttribute('download', `resume_${new Date().getTime()}.pdf`);

            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } else {
            console.error('File path not available');
        }
    }



    download1(pdfpath: string | null): void {
        if (pdfpath) {
            const timestamp = new Date().getTime(); // Get current timestamp
            const absoluteUrl: string = `https://letshelp.breakingindiaapp.com/Webservices/${pdfpath}?t=${timestamp}`;

            // Create a temporary anchor element
            const link = document.createElement('a');
            link.href = absoluteUrl;

            // Set the download attribute to specify the filename or leave it empty for automatic handling
            const fileName = pdfpath.split('/').pop(); // Get the file name from the path
            link.setAttribute('download', fileName || 'downloaded_file.pdf');

            // Append link to the body
            document.body.appendChild(link);

            // Programmatically click the link to trigger the download
            link.click();

            // Remove the anchor element from the DOM
            document.body.removeChild(link);
        } else {
            console.error('File path is not available');
        }
    }





    downloadFile(filePath: string): void {
        debugger
        const absoluteUrl: string = `https://letshelp.breakingindiaapp.com/Webservices/${filePath}`;

        // Create a temporary anchor element
        const link = document.createElement('a');
        link.href = absoluteUrl;
        link.setAttribute('download', ''); // This will set the download attribute to force download
        document.body.appendChild(link);

        // Simulate a click on the anchor element
        link.click();

        // Remove the anchor element from the DOM
        document.body.removeChild(link);
    }

    Subscribedd() {
        debugger
        if (!this.TransctionID) {
            this.generalService.ShowAlert("ERROR", 'Some thing went wrong', 'error');
            return;
        }
        this.arr = [];
        this.arr.push({
            RID: this.loginDetails[0].RID,
            TransctionID: this.TransctionID,
        });
        var uploadfile = new FormData();
        uploadfile.append("Param", JSON.stringify(this.arr));
        uploadfile.append("Flag", '10');
        var url = "api/Njobs/RecruiterRegister_Crud";
        this.generalService.PostData(url, uploadfile).then(data => {
            this.Subscribedata = data;
            if (data == 'SUCCESS') {
                this.TransctionID = '';
                this.generalService.ShowAlert("SUCCESS", 'Your TransctionId Submited.Please wait admin will conform your Transction', 'success');
            }
            else if (data == 'AlerdyExists') {
                this.generalService.ShowAlert("Alert", 'Your TransctionId Already Submited.Please enter valid TransctionID', 'alert');
            }
            else {
                this.generalService.ShowAlert("ERROR", 'Some thing went wrong.Please try again later', 'error');
            }
        });
    }

    getPackages() {
        debugger
        this.arr = [];
        this.arr.push({

        })

        var uploadfile = new FormData();
        uploadfile.append("Param", JSON.stringify(this.arr));
        uploadfile.append("Flag", '4');
        var url = "api/Njobs/NJ_PackageCRUD"
        this.generalService.PostData(url, uploadfile).then(data => {
            debugger
            this.Packagedata = data;
        })

    }

}

